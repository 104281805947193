import * as firebase from 'firebase'

export default class InterpretationRequest {

	constructor(language, type, location, startTime, duration, customerName, notes, ordererName, ordererEmail, ordererPhone, billingInfo, organization, attachment) {
		this.timestamp = new Date() // Date for identifying when request was saved

		this.language = language
		this.type = type
		this.location = location
		this.startTime = startTime
		this.duration = duration
		this.customerName = customerName // Person that needs interpretation
		this.notes = notes

		// Orderer info
		this.ordererName = ordererName // Person that orders interpretation
		this.ordererEmail = ordererEmail
		this.ordererPhone = ordererPhone
		this.billingInfo = billingInfo
		this.organization = organization
		this.attachment = attachment //Attachment id
	}

	uploadAttachment = (file) => {
		return new Promise((resolve, reject) => {
			const ref = firebase.storage().ref('request_attachments/' + this.attachment)
			ref.put(file)
				.then(() => {
					resolve()
				})
				.catch(err => {
					console.error(err)
					reject()
				})
		});
	}

	send = () => {

		return new Promise((resolve, reject) => {
			const db = firebase.firestore()
			const dbRef = db.collection('interpretationRequests').doc()
			let data = {
				timestamp: this.timestamp,
				language: this.language,
				type: this.type,

				ordererName: this.ordererName,
				ordererEmail: this.ordererEmail,
				organization: this.organization
			}

			if (this.location) data.location = this.location
			if (this.startTime) data.startTime = this.startTime
			if (this.duration) data.duration = this.duration
			if (this.customerName) data.customerName = this.customerName
			if (this.notes) data.notes = this.notes
			if (this.billingInfo) data.billingInfo = this.billingInfo
			if (this.ordererPhone) data.ordererPhone = this.ordererPhone

			if (this.attachment) data.attachment = this.attachment


			dbRef.set(data)
				.then(function() {
					resolve()
				})
				.catch(function(error) {
					console.error("Error writing document: ", error);
					reject()
				});
		});
	}


}