import React, {Component} from 'react';
import Select from 'react-select';
//import 'react-select/dist/react-select.css';
import PropTypes from 'prop-types'
import './single-select-dropdown.css'

export default class SingleSelectDropdown extends Component {

   static propTypes = {
		value: PropTypes.any,
		options: PropTypes.array.isRequired,
		onChange: PropTypes.func.isRequired,
		disabled: PropTypes.bool,
		searchable: PropTypes.bool,
		clearable: PropTypes.bool,
		error: PropTypes.bool, // Error makes borders red
		errorMessage: PropTypes.string,
   }


   // constructor(props) {
   //    super(props)
   //    this.state = {
   //       value: this.props.value ? this.props.value : null
   //    }
	// }

  render() {

   const { placeholder, title, clearable, searchable, value, onChange, error, options, errorMessage } = this.props;

	return (
		<div className="dropdown-container">
			{title &&
			<p>{title}</p>
		}

		<Select
			className={"single-select-dropdown" + (error ? " error-dropdown" : "")}
			name="single-select-field"
			closeOnSelect={true}
			disabled={this.props.disabled}
			onChange={onChange}
			options={options}
			placeholder={placeholder ? placeholder : ""}
			simpleValue
			searchable={searchable ? searchable : false}
			value={value}
			clearable={clearable || true}
		/>

		{errorMessage &&
			<p className="error-message-label">{errorMessage}</p>
		}

		</div>

	);
}

}