import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase'


window.$ = window.jQuery = require('jquery')  // Required for Bootstrap
// window.Tether = require('tether')  // Required for Bootstrap
window.Popper = require('popper.js')  // // Required for Bootstrap
require('bootstrap')  // Needed only for Bootstrap JavaScript features

const config = {
	apiKey: "AIzaSyBDbtLccYtOnY8nTOjFCZZXLw4Uz-vk-GI",
	authDomain: "pohjois-savon-tukkikeskus.firebaseapp.com",
	databaseURL: "https://pohjois-savon-tukkikeskus.firebaseio.com",
	projectId: "pohjois-savon-tukkikeskus",
	storageBucket: "pohjois-savon-tukkikeskus.appspot.com",
	messagingSenderId: "1037178435444",
	appId: "1:1037178435444:web:fd09f8343edd6cca"
};
firebase.initializeApp(config)

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
