import React, {Component} from 'react';
import './dialog.css'
import Button from '../Button/button'

export default class Dialog extends Component {

  render() {

	const { title, primatyBtnTitle, onClose, message } = this.props

    return (
		<div className="dialog-container">
			<div className="dialog">
				<p className="title">{title}</p>
				<p>{message}</p>
				<div className="dialog-buttons">
					{/* <Button styleType="secondary" round title="Peruuta" onClick={this.props.cancelPressed}/> */}
					<Button styleType="primary" round title={primatyBtnTitle || "OK"} onClick={onClose}/>
				</div>
			</div>
		</div>
    );
  }

}
