import React, {Component} from 'react';
import "react-datepicker/dist/react-datepicker.css";
import './bro-theme.css'
import './App.scss';
import Input, { ValueToState } from './components/Input/input';
import Button from './components/Button/button';
import LoadingSpinner from './components/LoadingSpinner/loading-spinner'
import SingleSelectDropdown from './components/SingleSelectDropdown/single-select-dropdown';
import DatePicker from 'react-datepicker'
import InterpretationRequest from './model/interpretation-request';
import Dialog from './components/Dialog/dialog';
import DataService from './services/data-service'
import languageCodes from './language-codes.json'
import {isEmpty} from 'lodash'
import classnames from 'classnames'
import $ from 'jquery'
import uuidv1 from 'uuid/v1'

import { registerLocale, setDefaultLocale } from 'react-datepicker';
import fi from 'date-fns/locale/fi'


export default class App extends Component {

	constructor(props) {
		super(props)

		this.state = {
			isLoading: false,

			startTime: undefined,
			duration: null,
			language: null,
			interpretationType: null,
			location: "",
			customerName: "",
			notes: "",

			ordererName: "",
			organization: "",
			ordererEmail: "",
			ordererPhone: "",
			billingInfo: "",

			success: false,
			attachmentFailure: false,
			error: undefined,
			inputErrors: {},

			languageOptions: [],
		}

		//NOTE: IF These are changed - update also to tulkki-24-7-hallinta
		this.types = {
			PUHELINTULKKAUS: "puhelintulkkaus",
			LÄSNÄOLOTULKKAUS: "läsnäolotulkkaus",
			ILMOITUSTULKKAUS: "ilmoitustulkkaus",
			//PIKATULKKAUS: "pikatulkkaus",
			KÄÄNNOS: "käännös"
		}

		this.interpretationTypes = [
         {value: this.types.ILMOITUSTULKKAUS, label: "Ilmoitustulkkaus"},
         {value: this.types.LÄSNÄOLOTULKKAUS, label: "Läsnäolotulkkaus"},
         //{value: this.types.PIKATULKKAUS, label: "Pikatulkkaus"},
         {value: this.types.PUHELINTULKKAUS, label: "Puhelintulkkaus"},
         {value: this.types.KÄÄNNOS, label: "Käännös"},
      ]
	}

	componentDidMount() {
		// Set react-datepicker locale
		registerLocale('fi', fi);
		setDefaultLocale('fi');
	}

	componentWillMount() {
		this.setState({isLoading: true})
		this.getLanguages()
	}

	getLanguages() {
		DataService.getLanguages()
			.then(data => {
				const codes = data.from
				let options = []
				codes.forEach(code => {
					let name = "Tuntematon kieli"
					const language = languageCodes.find(lang => code === lang.code)
					if (language) {
						name = (language.fi)
					}
					options.push(
						{value: code, label: name},
					)
				})
				this.setState({languageOptions: options, isLoading: false})
			})
			.catch(() => {
				this.setState({error: "Ongelmia sivun lataamisessa. Yritä uudelleen", isLoading: false})
			})
		//DataService
	}

	resetAll = () => {
		this.setState({
			isLoading: false,
			startTime: undefined,
			duration: null,
			language: null,
			interpretationType: null,
			location: "",
			customerName: "",
			notes: "",
			ordererName: "",
			ordererEmail: "",
			ordererPhone: "",
			organization: "",
			billingInfo: "",
			success: false,
			error: undefined,
			attachmentFailure: false,
			inputErrors: {},
			attachment: undefined,
		})
	}

	validateInterpretationRequest = () => {
		this.setState({isLoading: true})

		const { language, interpretationType, location, startTime, duration, customerName, notes, ordererName, ordererEmail, ordererPhone, billingInfo, organization, attachment } = this.state

		let inputErrors = {}

		let _startTime = startTime

		const _ordererName = ordererName.trim()
		const _organization = organization.trim()
		const _ordererEmail = ordererEmail.trim()
		const _ordererPhone = ordererPhone.trim()
		const _billingInfo = billingInfo.trim()

		const _language = language ? language.value : undefined
		const _interpretationType = interpretationType ? interpretationType.value : undefined
		let _location = location.trim()
		let _customerName = customerName.trim()
		const _notes = notes.trim()
		let _duration = duration ? duration.value : undefined

		//VALIDATE
		// Time not required for käännös or ilmoitus
		if (!startTime && interpretationType && interpretationType.value !== this.types.KÄÄNNOS && interpretationType.value !== this.types.ILMOITUSTULKKAUS) inputErrors.startTime = true
		if (!_ordererName) inputErrors.ordererName = true
		if (!_organization) inputErrors.organization = true
		if (!_ordererEmail) inputErrors.ordererEmail = true
		if (!_language) inputErrors.language = true
		if (!interpretationType) inputErrors.interpretationType = true
		// Location required only for läsnäolotulkkaus
		if (!_location && interpretationType && interpretationType.value === this.types.LÄSNÄOLOTULKKAUS) inputErrors.location = true
		//Duration required Only for puhelin and läsnäolo
		if (!_duration && interpretationType && (interpretationType.value === this.types.PUHELINTULKKAUS || interpretationType.value === this.types.LÄSNÄOLOTULKKAUS)) inputErrors.duration = true

		this.setState({inputErrors})
		if (!isEmpty(inputErrors)) {
			this.setState({error: "Täytä kaikki vaaditut kentät", isLoading: false})
			return
		}

		//Clear unwanted values - if user has changed interpretation type and some hidden input values still exist
		switch (interpretationType.value) {
			case this.types.ILMOITUSTULKKAUS:
				_startTime = undefined
				_location = ""
				_duration = undefined
				break;
			case this.types.KÄÄNNOS:
				_startTime = undefined
				_location = ""
				_duration = undefined
				break;
			case this.types.LÄSNÄOLOTULKKAUS:
				break;
			case this.types.PUHELINTULKKAUS:
				_location = ""
				break;
			default:
				break;
		}

		const attachmentId = (attachment && _interpretationType === this.types.KÄÄNNOS) ? uuidv1() : undefined
		const request = new InterpretationRequest(_language, _interpretationType, _location, _startTime, _duration, _customerName, _notes, _ordererName, _ordererEmail, _ordererPhone, _billingInfo, _organization, attachmentId)

		if (attachmentId) {
			request.uploadAttachment(attachment)
				.then(() => {
					this.sendRequest(request)
				})
				.catch(() => {
					this.setState({
						attachmentFailure: true,
						isLoading: false,
					})
				})
		} else {
			this.sendRequest(request)
		}
	}

	sendRequest = (request) => {
		request.send()
			.then(() => {
				this.setState({
					success: true,
					isLoading: false
				})
			})
			.catch((err) => {
				console.error(err)
				this.setState({
					error: "Tulkkauspyyntö epäonnistui. Tarkasta verkkoyhteytesi ja yritä uudellen",
					isLoading: false,
				})
			})
	}


	createDurationOptions = (min, max) => {
		const options = []
		for (var x = min; x <= max; x = x + 15) {
			const minutes = x%60
			const hours = (x - minutes) / 60
			let timeString = ""
			if (hours > 0) {
				timeString = hours + "t "
			}
			if (minutes > 0) {
				timeString += minutes + "min"
			}
			options.push(
				{value: x, label: timeString},
			)
		}
		return options
	}


	addFilesPressed = () => {
		$(".add-files").click();
	}

	render() {

		const { isLoading, startTime, success, error, languageOptions, inputErrors, language, interpretationType, duration, location, customerName, notes, ordererName, ordererEmail, ordererPhone, organization, billingInfo, attachment, attachmentFailure } = this.state

		let durationOptions = []
		if (interpretationType) {
			switch (interpretationType.value) {
				case this.types.PUHELINTULKKAUS:
					durationOptions = this.createDurationOptions(30, 360)
					break
				case this.types.LÄSNÄOLOTULKKAUS:
					durationOptions = this.createDurationOptions(60, 480)
					break
				default:
					break
			}
		}

		return (
				<div className="App">
					<header className="App-header">
						<h4>Pohjois-Savon Tulkkikeskus</h4>
					</header>

					<div className="page-content-container">

						<h2 style={{marginBottom: '3rem', marginTop: '1rem'}}>Tulkkaus tilauslomake</h2>

						<h4>Tilaus</h4>
						<SingleSelectDropdown
							title="Kieli*"
							options={languageOptions}
							value={language}
							searchable={true}
							clearable={true}
							onChange={(value) => this.setState({language: value})}
							error={inputErrors.language}
						/>

						<SingleSelectDropdown
							title="Tulkkaus (tyyppi)*"
							options={this.interpretationTypes}
							value={interpretationType}
							onChange={(value) => this.setState({interpretationType: value})}
							error={inputErrors.interpretationType}
						/>

						{/* TODO: Add error */}
						{ (interpretationType && interpretationType.value !== this.types.KÄÄNNOS && interpretationType.value !== this.types.ILMOITUSTULKKAUS) &&
							<div className={classnames("date-picker-container", {"error": inputErrors.startTime})}>
								<p className="subtitle">Ajankohta*</p>
								<DatePicker
									id="datePickerStart"
									selected={startTime}
									onChange={(date) => {
										this.setState({startTime: date})
									}}
									showTimeSelect
									timeFormat="HH:mm"
									timeIntervals={5}
									dateFormat="dd.MM.yyyy, HH:mm"
									timeCaption="klo"
									disabled={isLoading}
								/>
							</div>
						}

						{ durationOptions.length > 0 &&
							<SingleSelectDropdown
								title="Arvioitu kesto"
								value={duration}
								options={durationOptions}
								onChange={(value) => this.setState({duration: value})}
								searchable={false}
								clearable={true}
								error={inputErrors.duration}
							/>
						}

						{/* Location required only for läsnäolotulkkaus */}
						{ (interpretationType && interpretationType.value === this.types.LÄSNÄOLOTULKKAUS) &&
							<Input
								title="Paikka / Osoite*"
								rows={2}
								disabled={isLoading}
								value={location}
								error={inputErrors.location}
								valueToState={new ValueToState(this, "location")}
							/>
						}

						{/* not for käännös */}
						{ (interpretationType && interpretationType.value !== this.types.KÄÄNNOS) &&
							<Input
								title="Tulkattavan nimi"
								disabled={isLoading}
								value={customerName}
								error={inputErrors.customerName}
								valueToState={new ValueToState(this, "customerName")}
							/>
						}

						<Input
							title="Lisätietoja"
							disabled={isLoading}
							rows={5}
							value={notes}
							error={inputErrors.notes}
							valueToState={new ValueToState(this, "notes")}
						/>


						{/* Attachment field - Currently only for käännös */}
						{ (interpretationType && interpretationType.value === this.types.KÄÄNNOS) &&
							<div>
								<p style={{fontSize: '14px', margin: 0}}>Liitetiedosto</p>
								{ attachment &&
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<p style={{fontSize: '1.1rem', margin: 0, marginRight: '24px', padding: '4px 16px', border: '1px solid rgb(204, 204, 204)', borderRadius: '4px'}} >{attachment.name}</p>

										<Button
											title="Poista liite"
											styleType={Button.StyleType.DANGER}
											style={{margin: 0}}
											onClick={() => this.setState({attachment: undefined})}
										/>

									</div>
								}
								<input
									style={{height: 0, width: 0, opacity: 0}}
									className="add-files"
									accept="*"
									type="file"
									name="files[]"
									id="file"
									onChange={(event) => {
										const file = event.target.files ? event.target.files[0] : undefined
										if (file) {
											this.setState({attachment: file})
										}
									}}
								/>
								{ !attachment &&
									<Button
										title="Lisää liite"
										onClick={this.addFilesPressed}
									/>
								}
								<br/>
								<br/>
							</div>
						}

						<br/>
						<h4>Tilaajan yhteystiedot</h4>
						<Input
							title="Nimi*"
							disabled={isLoading}
							value={ordererName}
							error={inputErrors.ordererName}
							valueToState={new ValueToState(this, "ordererName")}
						/>
						<Input
							title="Yksikkö*"
							disabled={isLoading}
							value={organization}
							error={inputErrors.organization}
							valueToState={new ValueToState(this, "organization")}
						/>
						<Input
							title="Sähköposti*"
							disabled={isLoading}
							value={ordererEmail}
							error={inputErrors.ordererEmail}
							valueToState={new ValueToState(this, "ordererEmail")}
						/>
						<Input
							title="Puhelinnumero"
							disabled={isLoading}
							value={ordererPhone}
							error={inputErrors.ordererPhone}
							valueToState={new ValueToState(this, "ordererPhone")}
						/>
						<Input
							title="Laskutustiedot (mikäli ei ole jo toimitettu)"
							rows={2}
							disabled={isLoading}
							value={billingInfo}
							valueToState={new ValueToState(this, "billingInfo")}
						/>

						<div style={{display: 'flex', justifyContent: 'flex-end'}}>
							<Button
								title="Lähetä tilaus"
								round
								style={{width: '180px', marginRight: 0, marginTop: '2rem'}}
								onClick={this.validateInterpretationRequest}
								disabled={isLoading}
							/>
						</div>
						<br/>
					</div>

					{ isLoading && <LoadingView/> }
					{ success &&
						<Dialog
							title="Tilaus lähetetty"
							message="Lähetämme sähköpostiisi vahvistuksen, kun tilaus on käsitelty. Olethan meihin suoraan yhteydessä, mikäli tilaukseen tulee muutoksia."
							onClose={this.resetAll}
						/>
					}
					{ attachmentFailure &&
						<Dialog
							title="Lähetys epäonnistui"
							message="Liitetiedoston lähetys opäonnistui."
							onClose={this.setState({attachmentFailure: false})}
						/>
					}
					{ error &&
						<Dialog
							title="Virhe"
							message={error}
							onClose={() => this.setState({error: undefined})}
						/>
					}
				</div>
		);
	}
}

const LoadingView = () => {
	return (
		<div className="loading-view">
			<LoadingSpinner />
		</div>
	)
}
