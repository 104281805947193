import * as firebase from 'firebase'

export default class DataService {

	static getLanguages = () => {

		return new Promise((resolve, reject) => {
			const DATABASE = firebase.firestore()
			var docRef = DATABASE.collection("settings").doc("languages");

			docRef.get().then(function(doc) {
				if (doc.exists) {
					resolve(doc.data())
				} else {
					// doc.data() will be undefined in this case
					reject()
					console.log("No such document!");
				}
			}).catch(function(error) {
				console.log("Error getting document:", error);
			});
		});
	}

}